import { Outlet, Route, Routes, Link, Navigate } from "react-router-dom";
import { Login } from "./components/Login";
import { toAbsoluteUrl } from "../../../_edumate/helpers";
import { ForgotPassword } from "./components/ForgotPassword";

const AuthLayout = () => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-6 d-none d-lg-flex align-items-center justify-content-center bg-gradient-primary">
                  <Link to="/dashboard" className="p-5">
                    <img
                      alt="Logo"
                      className="img-fluid p-1"
                      style={{ WebkitFilter: "invert(100%)" }}
                      src={toAbsoluteUrl("/media/logos/logo.png")}
                    />
                  </Link>
                </div>
                <div className="col-lg-6">
                  <div className="p-5">
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AuthPage = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route index element={<Navigate to="/auth/login" />} />
      </Route>
    </Routes>
  );
};

export { AuthPage };
