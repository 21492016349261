import { useState } from "react";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Auth } from "aws-amplify";
import { useQuery } from "../../../../_edumate/helpers";

const ForgotPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();

  const initialValues = {
    username: "",
  };

  const schema = Yup.object().shape({
    username: Yup.string()
      // .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        await Auth.forgotPassword(values.username);
        setLoading(false);
        nav(`/auth/forgot-password?username=${values.username}`);
      } catch (error) {
        setStatus({ alert: "danger", message: error.message });
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <form className="row g-3" noValidate onSubmit={formik.handleSubmit}>
      <div className="text-center mb-3">
        {/* begin::Title */}
        <h1 className="h3 text-dark mb-3">Forgot Password?</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-400 fw-bold fs-6">
          Enter your email to reset your password.
        </div>
        {/* end::Link */}
      </div>

      {formik.status && (
        <div className={`mb-lg-15 alert alert-${formik.status.alert} small`}>
          <div className="alert-text font-weight-bold">{formik.status.message}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className="col-md-12 mb-2">
        <label className="form-label fw-bolder text-gray-900 fs-6">Username</label>
        <input
          type="text"
          placeholder="Username"
          autoComplete="off"
          {...formik.getFieldProps("username")}
          className={`
                          form-control form-control-solid
                          ${
                            formik.touched.packageURL &&
                            formik.errors.packageURL &&
                            "is-invalid"
                          }
                          ${
                            formik.touched.packageURL &&
                            !formik.errors.packageURL &&
                            "is-valid"
                          }
                        `}
        />
        {formik.touched.username && formik.errors.username && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.username}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="text-center">
        <button type="submit" className="btn btn-primary me-4">
          <span className="indicator-label">Submit</span>
          {loading && (
            <span className="indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            className="btn btn-light-primary"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{" "}
      </div>
      {/* end::Form group */}
    </form>
  );
};

const ConfirmForgotPasswordForm = ({ username }) => {
  const [loading, setLoading] = useState(false);
  const nav = useNavigate();

  const initialValues = {
    username,
    code: "",
    newPassword: "",
  };

  const schema = Yup.object().shape({
    username: Yup.string()
      // .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    code: Yup.string()
      .test('len', 'Must be exactly 6 characters', val => val.length === 6)
      .required("Code is required"),
    newPassword: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        await Auth.forgotPasswordSubmit(
          values.username,
          values.code,
          values.newPassword
        );
        nav(`/auth/login`);
        setLoading(false);
      } catch (error) {
        setStatus({ alert: "danger", message: error.message });
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <form className="row g-3" noValidate onSubmit={formik.handleSubmit}>
      <div className="text-center mb-10">
        {/* begin::Title */}
        <h1 className="h3 text-dark mb-3">Confirm Forgot Password</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-400 fw-bold fs-6">
          Enter your code and new password to reset your password.
        </div>
        {/* end::Link */}
      </div>

      {formik.status && (
        <div className={`mb-lg-15 alert alert-${formik.status.alert} small`}>
          <div className="alert-text font-weight-bold">{formik.status.message}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className="col-md-12 mb-2">
        <label className="form-label fw-bolder text-gray-900 fs-6">Username</label>
        <input
          type="text"
          placeholder="Username"
          disabled
          autoComplete="off"
          {...formik.getFieldProps("username")}
          className={`
                          form-control form-control-solid
                          ${
                            formik.touched.username &&
                            formik.errors.username &&
                            "is-invalid"
                          }
                          ${
                            formik.touched.username &&
                            !formik.errors.username &&
                            "is-valid"
                          }
                        `}
        />
        {formik.touched.username && formik.errors.username && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.username}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="col-md-12 mb-2">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              Code
            </label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type="text"
          autoComplete="off"
          placeholder="XXXXXX"
          {...formik.getFieldProps("code")}
          className={`
                          form-control form-control-solid
                          ${
                            formik.touched.code &&
                            formik.errors.code &&
                            "is-invalid"
                          }
                          ${
                            formik.touched.code &&
                            !formik.errors.code &&
                            "is-valid"
                          }
                        `}
        />
        {formik.touched.code && formik.errors.code && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.code}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="col-md-12 mb-2">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              New Password
            </label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type="password"
          autoComplete="off"
          placeholder="New password"
          {...formik.getFieldProps("newPassword")}
          className={`
                          form-control form-control-solid
                          ${
                            formik.touched.newPassword &&
                            formik.errors.newPassword &&
                            "is-invalid"
                          }
                          ${
                            formik.touched.newPassword &&
                            !formik.errors.newPassword &&
                            "is-valid"
                          }
                        `}
        />
        {formik.touched.newPassword && formik.errors.newPassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.newPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-primary me-4"
        >
          <span className="indicator-label">Submit</span>
          {loading && (
            <span className="indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-light-primary"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{" "}
      </div>
      {/* end::Form group */}
    </form>
  );
};

const ForgotPassword = () => {
  const query = useQuery();
  const username = query.get("username");

  return !username ? (
    <ForgotPasswordForm />
  ) : (
    <ConfirmForgotPasswordForm username={username} />
  );
};

export { ForgotPassword };
