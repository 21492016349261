import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Filter = ({ filterText, onFilter, onClear }) => (
  <div className="col-md-12 mb-3">
    <div className="input-group">
      <input
        id="search"
        type="text"
        className="form-control bg-light border-0 small"
        placeholder="Filter by Name or Report Id or Module"
        aria-label="Search"
        aria-describedby="addon-filter"
        value={filterText}
        onChange={onFilter}
      />
      <button
        id="addon-filter"
        className="btn btn-primary"
        type="button"
        onClick={onClear}
      >
        <FontAwesomeIcon icon={"xmark"} fixedWidth />
      </button>
    </div>
  </div>
);

export { Filter };
