import { useState, useEffect } from "react";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Auth } from "aws-amplify";
import { useAuth } from "../core/Auth";
import { useQuery } from "../../../../_edumate/helpers";

let userChallenge = null;

const ForcePasswordChangeForm = () => {
  const [loading, setLoading] = useState(false);
  const { setCurrentUser } = useAuth();
  const nav = useNavigate();

  useEffect(() => {
    if (!userChallenge)
      nav(`/auth/login`);
  }, [nav])

  const initialValues = {
    newPassword: "",
  };

  const schema = Yup.object().shape({
    newPassword: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        await Auth.completeNewPassword(
          userChallenge,
          values.newPassword
        );
        const user = await Auth.currentAuthenticatedUser();
        const creds = await Auth.currentUserCredentials()
        const { accessToken } = await Auth.currentSession();
        const groups = accessToken.payload["cognito:groups"] || [];
        if (user) {
          setCurrentUser({ user, groups, identityId: creds.identityId });
        }
        setLoading(false);
      } catch (error) {
        setStatus({ alert: "danger", message: error.message });
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <form className="row g-3" noValidate onSubmit={formik.handleSubmit}>
      <div className="text-center mb-10">
        {/* begin::Title */}
        <h1 className="h3 text-dark mb-3">Confirm New Password</h1>
        {/* end::Title */}
      </div>

      {formik.status && (
        <div className={`mb-lg-15 alert alert-${formik.status.alert} small`}>
          <div className="alert-text font-weight-bold">{formik.status.message}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className="col-md-12 mb-2">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              New Password
            </label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type="password"
          placeholder="New password"
          autoComplete="off"
          {...formik.getFieldProps("newPassword")}
          className={`
                          form-control form-control-solid
                          ${
                            formik.touched.newPassword &&
                            formik.errors.newPassword &&
                            "is-invalid"
                          }
                          ${
                            formik.touched.newPassword &&
                            !formik.errors.newPassword &&
                            "is-valid"
                          }
                        `}
        />
        {formik.touched.newPassword && formik.errors.newPassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.newPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-primary me-4"
        >
          <span className="indicator-label">Submit</span>
          {loading && (
            <span className="indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/login">
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            className="btn btn-light-primary"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{" "}
      </div>
      {/* end::Form group */}
    </form>
  );
};

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const { setCurrentUser } = useAuth();
  const nav = useNavigate();

  const schema = Yup.object().shape({
    username: Yup.string()
      // .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is required"),
  });
  
  const initialValues = {
    username: "",
    password: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        let user = await Auth.signIn(values.username, values.password);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          userChallenge = user
          nav(`/auth/login?passwordChange=true`);
        }
        user = await Auth.currentAuthenticatedUser();
        const creds = await Auth.currentUserCredentials()
        const { accessToken } = await Auth.currentSession();
        const groups = accessToken.payload["cognito:groups"] || [];
        if (user) {
          setCurrentUser({ user, groups, identityId: creds.identityId });
        }
      
        setLoading(false);
      } catch (error) {
        setSubmitting(false);
        setLoading(false);
        setStatus({ alert: "danger", message: error.message });
      }
    },
  });

  return (
    <form className="row g-3" onSubmit={formik.handleSubmit} noValidate>
      {/* begin::Heading */}
      <div className="text-center mb-10">
        <h1 className="h4 text-dark mb-5">Sign In to Edumate Reports</h1>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className={`mb-lg-15 alert alert-${formik.status.alert} small`}>
          <div className="alert-text font-weight-bold">{formik.status.message}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className="col-md-12 mb-2">
        <label className="form-label fs-6 fw-bolder text-dark">Username</label>
        <input
          placeholder="Username"
          type="text"
          autoComplete="off"
          {...formik.getFieldProps("username")}
          className={`
                          form-control form-control-solid
                          ${
                            formik.touched.username &&
                            formik.errors.username &&
                            "is-invalid"
                          }
                          ${
                            formik.touched.username &&
                            !formik.errors.username &&
                            "is-valid"
                          }
                        `}
          
        />
        {formik.touched.username && formik.errors.username && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.username}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="col-md-12 mb-2">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              Password
            </label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to="/auth/forgot-password"
              className="link-primary fs-6 fw-bolder"
              style={{ marginLeft: "5px" }}
            >
              Forgot Password ?
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <input
          type="password"
          placeholder="Password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={`
                          form-control form-control-solid
                          ${
                            formik.touched.password &&
                            formik.errors.password &&
                            "is-invalid"
                          }
                          ${
                            formik.touched.password &&
                            !formik.errors.password &&
                            "is-valid"
                          }
                        `}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="text-center">
        <button
          type="submit"
          className="btn btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>

      </div>
      {/* end::Action */}
    </form>
  );
}

const Login = () => {

  const query = useQuery();
  const passwordChange = query.get("passwordChange");

  return !passwordChange ? (
    <LoginForm />
  ) : (
    <ForcePasswordChangeForm />
  );
}

export { Login }