import { useEffect, useState } from "react";
import { S3Download, toAbsoluteUrl } from "../";
import { useAuth } from "../../../app/modules/auth"

const Avatar = () => {

  const { currentUser: { user: { attributes, username } } } = useAuth();
  const [imageURL, setImageURL] = useState();

  useEffect(() => {
    const getImage = async () => {
      if (attributes.picture)
        setImageURL(await S3Download(attributes.picture));
    };
    getImage();
  }, [attributes.picture]);
     
  return (
    <>
      <span className="me-2 d-none d-lg-inline text-gray-600 small">
        {attributes.name || attributes.email || username}
      </span>
      <img
        className="img-profile rounded-circle"
        src={imageURL || toAbsoluteUrl("/media/avatars/blank.png")}
        alt={attributes.name || username}
      />
    </>
  )
};
export { Avatar };
