import { Storage } from "aws-amplify";

Storage.configure({ level: 'protected' });

const S3Upload = async (file, options = null) => {
  let filename = `${file.name}`;

  const stored = await Storage.put(filename, file, {
    contentType: file.type,
    ...options
  });

  return stored.key;
};

const S3Download = async (key, options = null) => await Storage.get(key, options)

export { S3Upload, S3Download };
