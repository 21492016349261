import { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { MasterLayout } from "../../_edumate/layout/MasterLayout";
// import { SettingsPage } from "../modules/settings/SettingsPage"

const EdumateRoutes = () => {

  const SettingsPage = lazy(() => import("../modules/settings/SettingsPage"));
  const OrganisationsPage = lazy(() => import("../modules/organisations/OrganisationsPage"));
  const UsersPage = lazy(() => import("../modules/users/UsersPage"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* <Route path="overview/*" element={<SettingsPage />} /> */}
        <Route
          path="*"
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />
        <Route
          path="organisations/*"
          element={
            <SuspensedView>
              <OrganisationsPage />
            </SuspensedView>
          }
        />
        <Route
          path="users/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route index element={<Navigate to="/settings/overview" />} />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView = ({ children }) => {
  TopBarProgress.config({
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { EdumateRoutes };
