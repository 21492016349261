import { useEffect, useState } from "react";
import { API } from "aws-amplify";

const Organisations = ({
  formik,
  fieldName,
  filter = null,
  label,
  filterEdumate = false,
  disabled = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [organisations, setOrganisations] = useState([]);

  useEffect(() => {
    const onLoadOrganisations = async (filter) => {
      setLoading(true);
      try {
        const { organisations } = await API.get("edumate", `/organisations`);
        setOrganisations(
          organisations
            .filter((org) => org.organisationId !== filter)
            .filter((org) => (filterEdumate ? org.name !== "Edumate" : true))
        );
        setLoading(false);
      } catch {
        setLoading(false);
      }
    };

    onLoadOrganisations(filter);
  }, [filter, filterEdumate]);

  return (
    <div className="col-md-12 mb-3">
      <label className="form-label fw-bolder text-dark fs-6">{label}</label>
      <select
        disabled={disabled}
        {...formik.getFieldProps(`${fieldName}`)}
        onChange={(e) => {
          formik.setFieldValue(
            `${fieldName}Name`,
            e.target.options[e.target.selectedIndex].text
          );
          formik.setFieldValue(
            `${fieldName}`,
            e.target.options[e.target.selectedIndex].value
          );
        }}
        className={`
                      form-select form-select-solid
                      ${
                        formik.touched[`${fieldName}`] &&
                        formik.errors[`${fieldName}`] &&
                        "is-invalid"
                      }
                      ${
                        formik.touched[`${fieldName}`] &&
                        !formik.errors[`${fieldName}`] &&
                        "is-valid"
                      }
                    `}
      >
        {loading && <option>Loading...</option>}
        {!loading && (
          <>
            <option value="">Choose...</option>
            {organisations &&
              organisations.map((organisation) => (
                <option
                  key={organisation.organisationId}
                  value={organisation.organisationId}
                >
                  {organisation.name}
                </option>
              ))}
          </>
        )}
      </select>
      {formik.touched[`${fieldName}`] && formik.errors[`${fieldName}`] && (
        <div className="invalid-feedback">{formik.errors[`${fieldName}`]}</div>
      )}
    </div>
  );
};
export { Organisations };
