const keys = { 
  name: "MODULE_REPORT_NEW",
  description: "REPORT_DESCRIPTION",
  module: "MODULE_ID",
  category: "MODULE_REPORT_TYPE_ID"
}

const category = [
  "Widget Report",
  "SQL w/ template",
  "SQL report",
  "Correspondence",
  "Job"
]

const module = [
  "Attendance",
  "Academic",
  "Enrolment",
  "Accounts",
  "Timetable",
  "Exports",
  "General",
  "Foundation",
  "Welfare",
  "Curriculum",
  "Learning"
]

const readingXml = async (xml, formik) => {
  for (const [key, value] of Object.entries(keys)) {
    const node = xml.getElementsByTagName(value)[0].childNodes[0];
    switch (key) {
      case 'module':
        formik.setFieldValue('module', module[node.nodeValue -1])
        break;
      case 'category':
        formik.setFieldValue('category', category[node.nodeValue -1])
        break;
      default:
        formik.setFieldValue(key, node ? node.nodeValue : '')
        break;
    }
  }
};

export { readingXml };
