import JSZip from "jszip";

const parser = new DOMParser();

const readingZip = async (file) => {
  const zip = new JSZip();
  const { files } = await zip.loadAsync(file);
  
  for (const key in files) {
    if (key.split(".").pop() === "xml")
      return zip
        .file(key)
        .async("string")
        .then((content) => parser.parseFromString(content, "text/xml"));
  }
};

export { readingZip };
