import React from "react";

const ErrorsPage = () => {
  return (
    <div className="ErrorBoundary text-center">
      <h3>Sorry there was a problem loading this page</h3>
    </div>
  );
};

export { ErrorsPage };
