import { Outlet } from "react-router-dom";
import { Topbar } from "./components/topbar/Topbar";
import { Sidebar } from "./components/sidebar/Sidebar";
import { Content } from "./components/Content";
import { Footer } from "./components/Footer";

const MasterLayout = () => {
  return (
    <>
      {/* <!-- Sidebar --> */}
      <Sidebar />
      {/* <!-- End of Sidebar --> */}

      {/* <!-- Content Wrapper --> */}
      <div id="content-wrapper" className="d-flex flex-column">
        {/* <!-- Main Content --> */}
        <div id="content">
          {/* <!-- Topbar --> */}
          <Topbar />
          {/* <!-- End of Topbar --> */}

          {/* <!-- Begin Page Content --> */}
          <Content>
            <Outlet />
          </Content>
          {/* <!-- End Page Content --> */}
        </div>
        {/* <!-- End of Main Content --> */}

        <Footer />
      </div>
    </>
  );
};

export { MasterLayout };
