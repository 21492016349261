import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { AuthInit } from "./modules/auth";
import 'bootstrap'
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object.keys(Icons)
  .filter((key) => key !== 'fas' && key !== 'prefix')
  .map((icon) => Icons[icon]);

library.add(...iconList);

const App = () => {
  return (
    <Suspense fallback={<h1>Loading...</h1>}>
      <AuthInit>
        <Outlet />      
      </AuthInit>
    </Suspense>
  );
};

export { App };
