import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { checkIsActive } from "../../../helpers";

const SidebarMenuItemLink = ({ classes, to, title, icon }) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);

  return (
      <Link className={`${classes} ${isActive ? "active" : ""}`} to={to}>
        {icon && <FontAwesomeIcon icon={icon} fixedWidth />}
        <span>{title}</span>
      </Link>
  );
};

export { SidebarMenuItemLink };
