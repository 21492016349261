import { useEffect } from "react";
import { Routes, Navigate } from "react-router-dom";
import { useAuth } from ".";

const Logout = () => {
  const { logout } = useAuth();
  useEffect(() => {
    logout();
    document.location.reload();
  }, [logout]);

  return (
    <Routes>
      <Navigate to="/auth/login" />
    </Routes>
  );
}

export { Logout }
