import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../modules/auth";
import { SplashScreen } from "../../../_edumate/helpers";

const Dashboard = () => {
  const {
    currentUser: {
      user: { attributes },
      groups,
    },
  } = useAuth();
  const [organisations, setOrganisations] = useState([]);
  const [loading, setLoading] = useState(false);
  const organisationId = attributes["custom:organisationId"];

  useEffect(() => {
    const onLoad = async () => {
      try {
        setLoading(true);
        if (!groups.includes("Admin"))
          setOrganisations([
            await API.get("edumate", `/organisations/${organisationId}`),
          ]);
        else {
          const { organisations } = await API.get("edumate", `/organisations`);
          setOrganisations(organisations);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        throw new Error(e);
      }
    };
    onLoad();
  }, [groups, organisationId]);

  return (
    <>
      {/* <!-- Reports Count --> */}
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
        <Link
          to="/reports/new"
          className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
        >
          <FontAwesomeIcon icon="add" fixedWidth className="me-1" />
          Create New Report
        </Link>
      </div>
      <div className="row">
        {loading ? (
          <SplashScreen />
        ) : (
          organisations.map((organisation) => (
            <div
              className="col-xl-3 col-md-6 mb-4"
              key={organisation.organisationId}
            >
              <div className="card border-left-primary shadow h-100 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                        {organisation.name} Reports (Count)
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">
                        {organisation.reportsCount}
                      </div>
                    </div>
                    <div className="col-auto">
                      <FontAwesomeIcon
                        icon="file"
                        fixedWidth
                        size="2x"
                        className="text-gray-300"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export { Dashboard };
