import { useEffect, useState } from "react";
import { API } from "aws-amplify";

const OrganisationsNoFormik = ({ setReports, label }) => {
  const [loading, setLoading] = useState(false);
  const [organisations, setOrganisations] = useState([]);

  useEffect(() => {
    const onLoadOrganisations = async () => {
      try {
        const { organisations } = await API.get("edumate", `/organisations`);
        setOrganisations(organisations);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };
    onLoadOrganisations();
  }, []);

  return (
    <div className="col-md-12 mb-3">
      <label className="form-label fw-bolder text-dark fs-6">{label}</label>
      {loading && <span>...loading</span>}
      {!loading && (
        <select
          className="form-select form-select-solid"
          onChange={(e) => setReports(e.target.value)}
        >
          <option value="">Choose...</option>

          {organisations &&
            organisations.map((organisation) => {
              return (
                <option
                  key={organisation.organisationId}
                  value={`${organisation.organisationId}`}
                >
                  {organisation.name}
                </option>
              );
            })}
        </select>
      )}
    </div>
  );
};
export { OrganisationsNoFormik };
