import { useState, useEffect, createContext, useContext, useRef } from "react";
import { Auth } from "aws-amplify";
import * as authHelper from "./AuthHelpers";
import { SplashScreen } from "../../../../_edumate/helpers/components/SplashScreen";

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  currentUser: {
    groups: [],
    identityId: null,
    user: null,
  },
  setCurrentUser: () => {},
  logout: () => {},
};

const AuthContext = createContext(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [auth] = useState(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState();

  const logout = async () => {
    await Auth.signOut();
    setCurrentUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        currentUser,
        setCurrentUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const user = await Auth.currentAuthenticatedUser();
          const creds = await Auth.currentUserCredentials();
          const { accessToken } = await Auth.currentSession();
          const groups = accessToken.payload["cognito:groups"] || [];
          if (user) {
            setCurrentUser({ user, groups, identityId: creds.identityId });
          }
        }
      } catch (error) {
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    const checkAuth = async () => {
      if (await auth) {
        requestUser();
      } else {
        logout();
        setShowSplashScreen(false);
      }
    };

    checkAuth();
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <SplashScreen /> : <>{children}</>;
};

export { useAuth, AuthProvider, AuthInit };
