import { Auth } from "aws-amplify";

const getAuth = async () => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    console.log(error);
  }
};

const saveAuth = async (auth) => {
  return auth
};

const removeAuth = async () => {
  return null
};

export { getAuth, saveAuth, removeAuth };
