import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { checkIsActive } from "../../../helpers";

const SidebarMenuItemWithSub = ({ children, to, title, icon, controls }) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);

  return (
    <li className={`nav-item ${isActive && "active"}`}>
      <Link 
        className={`nav-link collapsed`} 
        to={to} 
        data-bs-toggle="collapse"
        data-bs-target={`#${controls}`}
        aria-expanded="true"
        aria-controls={controls}
        >
        <FontAwesomeIcon icon={icon} fixedWidth size="xs" className="me-1"/>
        <span>{title}</span>
      </Link>
      {children}
    </li>
  );
};

export { SidebarMenuItemWithSub };
